/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
// @import '/node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
// @import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';

::ng-deep  .mat-paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
}

::ng-deep .mat-sort-header-arrow {
  height: 12px;
  width: 12px;
  min-width: 12px;
  position: relative;
  display: flex;
  opacity: 1;
  color: white !important;
}

::ng-deep .mat-sort-header-container {
  display:flex;
  justify-content:center;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #403a3a !important;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #5674b5 0%, #1fabad 66%, #506aae 100%)!important;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 12px;
  background: linear-gradient(to bottom, #919191 0%, #929e9f 66%, #818695 100%) !important;
}




/* Track */
.sidebar .sidebar-wrapper ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px red !important;
  border-radius: 1px;
}

/* Handle */
.sidebar .sidebar-wrapper ::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #56b573 0%, #1fabad 66%, #f06a1d 100%)!important;
  border-radius: 1px;
}

/* Handle on hover */
.sidebar .sidebar-wrapper ::-webkit-scrollbar-thumb:hover {
  width: 22px;
  background: linear-gradient(to bottom, #fc0808 0%, #929e9f 66%, #02ebf3 100%) !important;
}

.text-left1{
  margin-top: 12px;
}

body{
    font-family: 'roboto', sans-serif;
}
.main-content {
    padding: 0 20px;
}
.page-content-wrapper {
    height:calc(97vh - 24px);
    overflow: hidden;
    //padding-bottom: 100px;
    box-sizing: border-box;
}

.noDataFound{
  width:50%;
}


.header-footer-padding{
    padding-top: 40px;
    padding-bottom: 55px;
}

.allow-scroll{
    overflow: auto;
}

.inner-page-content1 {
    padding:0rem 3rem 0rem;
    height: calc(100vh - 256px);
    overflow: auto;
    box-sizing: border-box;
}

.container-fluid.inner-page-content.reportfixedpagenation
{
  height: calc(94vh - 154px)!important;
  overflow: auto;
  box-sizing: border-box;
}


  .frowbtn .form-group {
    // margin-bottom: 0px;
    margin-top: 3px!important;
}

.inner-page-contentfullH {
  height: 100vh!important;
}
.w35tbl
{
  width:35px;
}

.page-content-wrapperfullh {
    height: auto;
    overflow: hidden;
    box-sizing: border-box;
}

.inner-page-contentfullh {
    height: auto;
}
.inner-page-content {
    // padding:1rem 3rem 0rem;
    padding:0rem 3rem 0rem;
    height: calc(100vh - 153px);
    // height: calc(100vh - 227px);
    overflow: auto;
    box-sizing: border-box;
}
.inner-page-content_bottom_page {
    padding:4rem 3rem 0rem;
    height: calc(100vh - 300px);
    overflow: auto;
    box-sizing: border-box;
}

authentication-themeNew.auth-style_1 {
    border-bottom: 0.4px solid #415a91;
    padding-bottom: 30px;
    box-sizing: border-box;
    box-shadow: 3 0 #415a91;
  }
  
.authLoginAccessNewAuth {
    //background: #009494 !important;
   /*background: linear-gradient(90deg, #3a5183 0%,#415a91 10%,#4d6aaa 20%,
    #5171b6 30%,#5575b9 40%,#5a7dc9 50%,#6586d3 60%,#4e6baa 70%, #6487d1 80%, 
    #638be2 90%,#7099f1 100%) !important;*/
    //background: WHITE !important;
//    background: linear-gradient(WHITE) !important;
    background-image: url('/assets/images/smart-city-stock-image.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}


authentication-theme.auth-style_1 .logo-section {
    border-bottom: 0.4px solid #415a91;
    padding-bottom: 30px;
    box-sizing: border-box;
    box-shadow: 3 0 #415a91;
  }
  
.authLoginAccess {
    //background: #009494 !important;
   /*background: linear-gradient(90deg, #3a5183 0%,#415a91 10%,#4d6aaa 20%,
    #5171b6 30%,#5575b9 40%,#5a7dc9 50%,#6586d3 60%,#4e6baa 70%, #6487d1 80%, 
    #638be2 90%,#7099f1 100%) !important;*/
    //background: WHITE !important;
//    background: linear-gradient(WHITE) !important;
    background-image: url('/assets/images/Smart-city-concept.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 70%;
}

.authLoginAccessNew {
    background:#F5F4F9 !important;
    //background: #009494 !important;
   //background: linear-gradient(90deg,#268bce 0,#1f74ad 66%,#134a6e 100%) !important;
    //background: WHITE !important;
//    background: linear-gradient(WHITE) !important;
    /*background-image: url('/assets/images/Smart-city-concept.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 70%;*/
}

/*
.mat-expansion-panel-header.mat-expanded {
    //background: #415a91;
    background: lightgray;
    color:#fff;
  }*/

  .mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:focus {
  background:lightgray;
  max-height: 48px;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover
{
  background:lightgray;
  max-height: 48px;
}
 /* .mat-expansion-panel-header.mat-expanded
 {
    //background: #415a91;
    background: lightgray;
    //color:#fff;
  }
  
  .mat-expansion-panel-header.mat-expanded:focus
 {
    //background: #415a91;
    background: lightgray;
    //color:#fff;
  }
  
 
  .mat-expansion-panel-header.mat-expanded:hover{
    //background: #415a91;
    background: lightgray;
    //color:#fff;
  }*/

 
  /*.mat-expansion-panel-header.mat-expanded,
  .mat-expansion-panel-header.mat-expanded:hover
   {
   // background: linear-gradient(to bottom, rgb(48, 172, 255) 0%, rgb(41, 151, 224) 66%, rgb(34, 131, 196) 100%);
    background: lightgray;
    color:#fff;
//    background: #415a91;
  }*/

.grid-head .logo-section{
        padding: 20px 0 10px;
        margin: 0 !important;
}
.white-bg{
    background-color: rgba(255, 255, 255, 1) !important;
}

.deshHeadWcTitle{
    color: #000;
    font-weight: 400;
    font-size: 34px;
    display: inline-flex;
    vertical-align: middle;
    line-height: 34px;
    padding-top: 20px;
    box-sizing: border-box;
    padding-bottom: 0px;
    mat-icon{
        color: #415a91;
        font-size: 34px;
        margin-right: 10px;
    }
}
button.cPbg{
    background-color: #3eb6c4!important;
    color: #fff;
    margin-right: 5px;
    height: 32px!important;
    width: 32px!important;
    right: 0px;
    font-size: 12px;
    min-width: 32px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)!important;
}
.centerfa
{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_file_exporter {
    background-color: #415a91;
    color: #fff !important;
    width: 100%;
}
/*-----------color--------*/

.d-c{
    color: #415a91;
}
.d-c1{
  color:white;
}
.d-c2{
    color: rgb(158, 58, 73);
}
.d-c3{
    color: rgb(143, 82, 192);
}
.d-c4{
    color: rgb(61, 151, 167);
}
.d-c5{
    color: rgb(164, 175, 60);
}
.d-c6{
    color: rgb(196, 156, 47);
}
.d-c7{
    color: rgb(190, 109, 62);
}
.d-c8{
    color: rgb(71, 22, 28);
}
.d-c9{
    color: rgb(143, 101, 106);
}
.d-c10{
    color:#1fabac;
}
.d-c11{
    color: #13b9cd;
}
.d-c12{
    color: rgb(255, 134, 148);
}
.d-c13{
    color: rgba(129, 47, 206, 0.856);
}
.d-c14{
    color: rgba(233, 227, 142, 0.897);
}
.d-c15{
    color: rgb(9, 161, 207);
}

.d-c16{
    color: rgb(59, 160, 50);
}
/*-----------font-size--------*/
.fs-14{
    font-size: 14px;
    button{
        font-size: 14px;
    }
}
.fs-30 {
    //font-size: 30px !important;
}
/*-----------errors--------*/
.errors{
    color:  red !important;
}

.success{
    color:  rgb(0, 255, 21) !important;
}

/*----------widths----------*/
.w-300{
    width: 300px !important;
}
/*----------paddings----------*/
.pb-100{
    padding-bottom: 100px;
}
/*----------heights----------*/
.mxh-none {
    max-height: none !important;
}
.mh-700 {
    min-height: 700px;
}
.h-180{
    height: 180px !important;
}
.h-16 {
    height: 4rem;
}
.h-75 {
    height: 75px !important;
}
.h-40{
    height: 40px !important;
}

.cardMarginBottm {
    margin-bottom: 10px;
}

/****/
.mb0{
  margin-bottom: 0px !important;
}
.allutilitysStructure {
    line-height: 50px;
    font-size: 80px;
    span {
        font-size: 30px;
    }
}

.mat-card {
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    margin-left: -4rem;
    margin-right: -4rem;
}


.cardMarginRight {
    margin-left: 10px;
}
.pointer{
    cursor: pointer;
}
$mat-card-header-size: 200px !default;
mat-card.cardMarginRight.folderView.mat-card {
    border-radius: 0.25rem;
    box-shadow: 1 5px 5px -3px rgba(82, 63, 104, 0.06);
    //background-color:rgba(29, 42, 109, 0.1);
    overflow: hidden;
    word-break: inherit;
    display: block;
    text-align: center;
    margin: 7px;
    width: 200px;
    padding: 10px;
    background-color:rgb(238, 238, 238);
      //color: #fff;
      .card-footer {
          //color: #fff;
          //text-decoration-color: white;
          background-color: #d9dadb;
          .fullbutton {
              color: #fff;
              .mat-icon {
                position: absolute;
                right: 0;
                margin-top: -1px;
              }
          }
    }
    &:hover{
        background-color:rgb(238, 238, 238);
        //color: #fff;
        .card-footer {
            //color: #fff;
            //text-decoration-color: white;
            background-color: #d9dadb;
            .fullbutton {
                color: #fff;
                .mat-icon {
                  position: absolute;
                  right: 0;
                  margin-top: -1px;
                }
            }
        }
    }


    .mat-card-image{
        margin-top: 0;
    }

    .cardAction {
        position: absolute;
        right: 22PX;
        top: 3px;
    }

    .circular {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
      }
      .circular img {
        min-width: 100%;
        min-height: 100%;
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
  

    .mat-card-title {
        margin-top: -4px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        line-height: 10px;
        span {
            font-size: 14px;
            height: 20px !important;
            line-height: 20px;
        }
    }
    .utilityBg {
        width: 140px;
        height: 80px;
        background-position: center;
        background-size: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
    }
    .card-footer {
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: center space-around;
            align-items: center;
            background-color: #fff;
            .fullbutton {
                width: 100% !important;
                text-align: left;
                padding: 0;
                background: transparent;
                position: relative;
                color: #415a91;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-right: 0px;
                mat-icon {
                    position: absolute;
                    right: 0;
                }
            }
    }
}

mat-card-content.reportsTable {
    padding: 10px;
    overflow: auto;
    height: auto;
    max-height: 100vh;
    background: #415a91;
    h2{
      text-align: center;
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;
      padding: 10px 0;
    }
    
    .table-wrapper{
    margin: 5px 0 20px;
    }
    
    .table-wrapper table.fl-table {
    border-radius: 5px;
    font-size: 10px !important;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: pre-line !important;
    background-color: white;
    }
    
    .fl-table td, .fl-table th {
    text-align: center;
    padding: 4px;
    white-space: pre-line !important;
    }
    
    .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 10px;
    white-space: pre-line !important;
    }
    
    .fl-table thead th {
    color: #ffffff;
    background: #4FC3A1;
    white-space: pre-line !important;
    }
    
    .fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
    white-space: pre-line !important;
    }
    
    .fl-table tr:nth-child(even) {
    background: #F8F8F8;
    white-space: pre-line !important;
    }

    .scrollbar {
        background-color: #F5F5F5;
        float: left;
        height: 300px;
        margin-bottom: 25px;
        margin-left: 22px;
        margin-top: 40px;
        width: 65px;
        overflow-y: scroll;
    }
}

/*****status icons colors********/
.dtatusOnColor{
    color: #68B684 !important;
}
.dtatusOffColor{
    color: #000 !important;
}

/*******color*******/
.btn-primary {
    background-color: #415a91 !important;
}

/*********/
.commonClass {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: flex-start center;
    align-items: flex-start;
  }
  .flex {
    display: flex;
  }
  .headerArea {
    align-items: center;
   
    background: linear-gradient(90deg, #017a7a 0%, #415a91 15%, #028383 30%, #017c7c 45%, #415a91 60%, #415a91 75%, #415a91 90%, rgb(1, 29, 29) 100%);
    //background-color:#017c7c;
    color: #fff;
    box-sizing: content-box !important;
    display: flex;
    flex-direction: row;
    height: 120px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 1rem;
    place-content: center flex-start;
      .hAtitle {
        font: 500 18px/26px Roboto;
      }
  }
  
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; 
    background:#415a91;
    color: #fff;
  }

  .dilog-button {
    background-color: #415a91; /* Green */
    border: none;
    color: white;
    padding: 2px 35px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 1px 1px;
    transition-duration: 0.4s;
    height: 40px;
    cursor: pointer;
  }
  
  .dilog-button1 {
    background-color: white; 
    color: black; 
    border: 2px solid #415a91;
  }
  
  .dilog-button1:hover {
    background-color: #415a91;
    color: white;
  }
  
  .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    padding-top: 5px;
    padding-right: 30px;
    vertical-align: -5px;
    }
  

  .mb-1 {
    margin-bottom: .25rem;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mt-0 {
    margin-top: 0;
  }
.-mt-16 {
    margin-top: -4rem;
}

.-mt-20 {
    margin-top: -1rem;
}

/*
    custom padding settings
*/
.plr_c25px{
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.toolbar-space {flex: 1 1 auto;}

/*---card view--*/
.cardMarginRight {
    margin-left: 10px;
}
.subheaderArea{
    background-color: #fff;
}

.of-auto {
    overflow: unset;
}

// .avatar-circle {
//     width: 100px;
//     height: 100px;
//     background-color: none;
//     text-align: center;
//     margin: 0 auto;
//     border-radius: 50%;
//     -webkit-border-radius: 50%;
//     -moz-border-radius: 50%;
//     .initials {
//         position: relative;
//         top: 25px; /* 25% of parent */
//         font-size: 50px; /* 50% of parent */
//         line-height: 50px; /* 50% of parent */
//         color: #1B5299;
//         font-weight: bold;
//     }
//   }

@mixin avatar($size) {
    width: $size;
    height: $size;
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;

    .initials {
      position: relative;
      top: $size*0.25;
      font-size: $size*0.5;
      line-height: $size*0.5;
      color: #fff;
      font-weight: bold;
      letter-spacing: 3px;

      
    }
}

.consumerViewTitle{
    color: #000;
    font-weight: 400;
    font-size: 34px;
    font-size: bold;
    display: inline-flex;
    vertical-align: middle;
    line-height: 34px;
    padding-top: 20px;
    box-sizing: border-box;
    padding-bottom: 0px;
    mat-icon{
        color: rgba(39, 89, 252, 0.719);
        font-size: 34px;
        margin-right: 10px;
    }
}

.avatar-circle      { @include avatar(80px);    margin-bottom: 8px; }
.avatar-circle-sm   { @include avatar(30px); }
.avatar-circle-lg   { @include avatar(230px); }

.mat-card.allTableWithCard{
    padding: 0px;
    overflow: auto;
    .mat-card-footer{
        margin: 0;
    }
    .mat-row {
        height: 30px;
    }
    .mat-cell {
    padding: 2px 4px 2px 0;
    }
    .button-remove {
        background-color: red;
        color: #fff;
    }
    .optionMenuList .mat-menu-content{
        padding: 0 !important;
        mat-list{
            padding: 0 !important;
            .mat-list-item-content{
                padding: 0 !important;
                button{
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }

    tr.tableHeader th{
        text-align: center;
        font-size: medium;
        font-style: inherit;
        //font-weight: 700;
        font-weight: bold;
        background-color: #415a91;
        color: white;
    }
}

.authViewTitle{
    color: #000;
    font-weight: 400;
    font-size: 34px;
    font-size: bold;
    display: inline-flex;
    vertical-align: middle;
    line-height: 34px;
    padding-top: 20px;
    box-sizing: border-box;
    padding-bottom: 0px;
    mat-icon{
        color: #415a91;
        font-size: 34px;
        margin-right: 10px;
    }
}

tr.mat-footer-row {
    height: 30px !important;
    
  }
  

.sidebar .navigation-menu li a[aria-expanded="true"] {
    color: #415a91;
    &:hover{
        .navigation-submenu li a{
            background-color: #6d98f7;
            color: #415a91;
        }
    }
}

tr.tableHeader th{
    text-align: center;
    font-style:inherit;
    font-size: medium;
    font-weight: bold;
    border: 1px solid rgb(250, 245, 245);
    background-color : #415a91;
    color: white;
  }
  
.sidebar .navigation-menu li .navigation-submenu li a[aria-expanded="collapse"] {
    position: relative; 
}

.sbu-menu-icon{
    position: relative;
    height: 26px;
    i {
        position: absolute;
        top:4px;
        font-size: 20px;
    }
    span {
        padding-left: 25px;
    }
}

.with-icon {
    display: flex;
    align-items: center;
    .mat-icon {
        margin-right: 10px;
    }
}

.wdr-credits{
    display: none;
}

.default-wc-ff {
    mat-form-field{
        color: #fff;
        label {
            color: #fff;;
        }
    }
}

.theme-green .bs-datepicker-head {
    background-color: #eeeeee !important;
    .theme-green .bs-datepicker-body table td.week span {
        color: #6bc2cc;
    }
}

.theme-green .bs-datepicker-head {
    background-color: #6bc2cc!important;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #6bc2cc;
}
@media (max-width: 980px) { 
    .xs-w-125{
        width:30vw;
    }
}

@media (max-width: 765px) { 
    .inner-page-content {
        padding:5px 0px;
        // height: calc(100vh - 365px);
        height:auto;
    }
    .inner-page-content_bottom_page {
        padding:4rem 3rem 0rem;
        // height: calc(100vh - 350px);
         height:auto;
    }

    .xs-c-h{
        .mat-card-header-text{
            display: none;
        }
    }
    .xs-no-p{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .xs-dn{
        display: none !important;
    }
    .xs-no-mg{
        margin: 0 !important;
    }
    .xs-w-125{
        width:125px !important;
    }
    .xs-nPb{
        margin: 0 !important;
    }
}

.floating-button {
    display: flex;
    position: absolute;
    bottom:70px;
    right: 20px;
    z-index: 1;
  }

  .sml-btn {
    margin: 0 5px 7px !important;
    min-width: 50px !important;
    line-height: 30px !important;
  }

  .iframe-container {
    overflow: hidden;
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    padding-top: 56.25%;
    position: relative;
  }
   
  .iframe-container iframe {
     border: 0;
     height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
  }

  .timeStamp {
    width: 100%;
    clear: both;
    text-align: right;
    font-size: 10px;
    color: #b1aeae;
}

.cdk-visually-hidden
{
    visibility: hidden;
}

.mat-header-row {
    height: 35px;
  }
  
  .mat-row {
    height: 30px;
  }

  
  .container1 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container1 {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container1 {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container1 {
      max-width: 960px;
    }
      .avatar-circle .initials {
    font-size: 28px;
    
  }
  }
  @media (max-width: 992px) {
              .filter-report-options.card_view_box.ng-star-inserted{
                overflow: auto !important;
              }
              .sm-overflow-auto{
                overflow: auto !important;
              }
  }
  @media (min-width: 1200px) {
    .container1 {
      max-width: 1140px;
    }
  }

  .filter-report-options.card_view_box.ng-star-inserted{
    overflow: auto !important;
  }
  .sm-overflow-auto{
    overflow: auto !important;
  }

  .contact-area .contact-inner1 {
    padding: 40px 0px 0px 0px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .contact-area .contact-inner1 .contact-inner1-left {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 40px;
  }
  @media (max-width: 991.98px) {
    .contact-area .contact-inner1 .contact-inner1-left {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      border-right: none;
    }
  }
  @media (max-width: 575.98px) {
    .contact-area .contact-inner1 .contact-inner1-left {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      border-right: none;
    }
  }
  .contact-area .contact-inner1 .contact-inner1-left .section-title {
    font-size: 32px;
    text-transform: capitalize;
    margin-bottom: 10px;
    color: #002790;
  }
  .contact-area .contact-inner1 .contact-inner1-left h3 {
    font-size: 14px;
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area {
    margin-top: 20px;
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group .form-control {
    border-radius: 0;
    height: 60px;
    background: #f5f5f5;
    border: none;
    margin-bottom: 20px;
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input {
    width: 48%;
    float: left;
  }
  @media (max-width: 991.98px) {
    .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input {
      width: 100%;
    }
  }
  @media (max-width: 575.98px) {
    .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input {
      width: 100%;
    }
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input:first-child {
    margin-right: 10px;
  }
  @media (max-width: 991.98px) {
    .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input:first-child {
      margin-right: 0px;
    }
  }
  @media (max-width: 575.98px) {
    .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input:first-child {
      margin-right: 0px;
    }
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input:last-child {
    margin-left: 10px;
  }
  @media (max-width: 991.98px) {
    .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input:last-child {
      margin-left: 0px;
    }
  }
  @media (max-width: 575.98px) {
    .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .form-group input:last-child {
      margin-left: 0px;
    }
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area .btn-primary {
    color: #fff;
    background-color: #3674bd;
    border-color: #3674bd;
    text-transform: uppercase;
    border-radius: 0px;
    width: 100%;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
  }
  .contact-area .contact-inner1 .contact-inner1-left .contact-form-area textarea {
    height: 110px !important;
  }
  .contact-area .contact-inner1 .contact-inner1-right {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 40px;
  }
  @media (max-width: 991.98px) {
    .contact-area .contact-inner1 .contact-inner1-right {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0px;
      margin-top: 40px;
    }
  }
  @media (max-width: 575.98px) {
    .contact-area .contact-inner1 .contact-inner1-right {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0px;
      margin-top: 40px;
    }
  }
  .contact-area .contact-inner1 .contact-us-img img {
    width: 100%;
  }

  
  .mat-drawer-content {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow: visible!important;
    margin-left: 0px!important;

    /* overflow: auto; */
    //  overflow: visible;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon {
  width: 0.9em;
}

.centerNew {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 0%;
}

.icons {
  font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 32px;
    width: 35px;
    line-height: 1;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}


@media screen and (max-width: 1400px) {
  .page-content-wrapper {
    height: calc(97vh - 32px);

}
}
@media screen and (max-width: 991px) {
  .page-content-wrapper {
    height: auto;

}
.table-responsive_fixed {
  height: auto;
  overflow: auto;
}
}

[data-aos^=zoom][data-aos^=zoom] {
    opacity: 1!important;
    transition-property: opacity,transform;
}

i.fa.fa-file-excel-o {
  font-size: 14px !important;
    color: #fff !important;
    background: #3eb6c4;
    background: #89ebf7;
    color: #fff;
    background: #3eb6c4;
    border-radius: 10%;
    line-height: 25px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 24%);
    width: 25px;
    cursor: pointer;
    height: 25px;
    /* padding-top: 3px; */
    margin-top: 2px}

i.fa.fa-file-excel-o.iconcircle {
  font-size: 15px!important;
  color: #fff!important;
}

.monthflex button.dilog.dilog1 {
    height: 30px;
    font-size: 12px;
    margin-top: -4px;
}

button#dropdownMenuButton:after {
  top: 5px!important;
  position: absolute;
}
.export-linkss a.dropdown-item {
  border-radius: 0px!important;
}
.exclbtn {
  background: #fff!important;
  color: #333!important;
}

.export-linkss {
  margin: 10px 0px;
} 

.tooltipp {
  position: relative;
  display: inline-block;
}

.tooltipp .tooltiptextt {
  visibility: hidden;
  width: fit-content;
  background-color: #13b9cd;
  color: #fff;
  text-align: center;
  border: navajowhite;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 9px;
  right: auto;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  font-size: 12px;
  padding: 8px;
  border-radius: 10px;
}
.tooltipp .tooltiptexttright {
  visibility: hidden;
  width: fit-content;
  background-color: #13b9cd;
  color: #fff;
  text-align: center;
  border: navajowhite;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 9px;
  right: -50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  font-size: 12px;
  padding: 8px;
  border-radius: 10px;
}


.tooltipp:hover .tooltiptexttright {
  visibility: visible;
}
.tooltipp:hover .tooltiptextt {
  visibility: visible;
}




/////report table fixed
// https:www.w3docs.com/tools/code-editor/11516
#pdfTable {
  // overflow-y: auto;
  min-height: 470px;
  max-height: 100vh;
}
#pdfTable thead th {
  position: sticky;
  top: -1px;
}
#pdfTable table {
  border-collapse: collapse;
  width: 100%;
}
#pdfTable th,
#pdfTable td {
  padding: 2px 2px;
  border: 1px solid #ccc;
}
.subrepLI i.nc-icon.nc-ruler-pencil {
  font-size: 20px!important;
  margin-top: 5px;
}


.table-responsive_fixed {
  // overflow-y: auto;
  min-height: 500px;
  max-height: 100vh;
  overflow-x: initial!important;
}
#table-responsive_fixed thead th {
  position: sticky;
  top: 0;
}
.table-responsive_fixed table {
  border-collapse: collapse;
  width: 100%;
}
.table-responsive_fixed th,
.table-responsive_fixed td {
  padding: 8px 16px;
  border: 1px solid #ccc;
}

.subicn {
    font-size: 11px !important;
    background: none !important;
    box-shadow: none !important;
    color: #fff !important;
    padding-top: 3px!important;
}

/* // https:codepen.io/kkrueger/pen/qfoLa  
// Custom PDF Button dropdown */

$menu_WIDTH:120px;

.cstmdropdoen {
    display: block;
    position: relative;
    cursor: pointer;
    margin-top: -2px;
  }
  
  .menu-title {
    display: block;
    width: $menu_WIDTH;
    padding: 6px 0 7px;
    background: #3eb6c4;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    transition: 0.3s background-color;
    font-size: 14px;
    border-radius: 0;
    margin-top: 8px;
    
  }
  
  .menu-title:before {
    content: "";
    display: block;
    height: 0;
    border-top: 5px solid #20828e;
    border-left: ($menu_WIDTH / 2) solid transparent;
    border-right: ($menu_WIDTH / 2) solid transparent;
    border-bottom: 0 solid #dddddd;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 101;
    transition: 0.2s 0.2s border-top ease-out, 0.3s border-top-color;
  }
  
  .menu-title:hover {
    background: #20828e;
  }
  .menu-title:hover:before {
    border-top-color: #20828e;
  }
  
  .cstmdropdoen:hover > .menu-title:before {
    border-top-width: 0;
    transition: 0.2s border-top-width ease-in, 0.3s border-top-color;
  }
  
  .menu-title:after {
    content: "";
    display: block;
    height: 0;
    border-left: ($menu_WIDTH / 2) solid transparent;
    border-right: ($menu_WIDTH / 2) solid transparent;
    border-bottom: 0 solid #ebebeb;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 101;
    transition: 0.2s border-bottom ease-in;
  }
  
  .cstmdropdoen:hover > .menu-title:after {
    border-bottom-width: 5px;
    transition: 0.2s 0.2s border-bottom-width ease-out;
  }
  
  .menu-dropdown-export {
    min-width: 100%;
    padding: 15px 0;
    position: absolute;
    background: #fff;
    z-index: 100;
    transition: 0.5s padding, 0.5s background;
  }
  
  .menu-dropdown-export:after {
    content: "";
    display: block;
    height: 0;
    border-top: 5px solid #20828e;
    border-left: ($menu_WIDTH / 2) solid transparent;
    border-right: ($menu_WIDTH / 2) solid transparent;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 101;
    transition: 0.5s border-top;
  }
  
  .cstmdropdoen:not(:hover) > .menu-dropdown-export {
    padding: 0px 0;
    background: #fff;
    z-index: 99;
  }
  
  .cstmdropdoen:not(:hover) > .menu-dropdown-export:after {
    border-top-color: #dddddd;
  }
  
  .cstmdropdoen:not(:hover) > .menu-title:after {
    border-bottom-color: #dddddd;
  }
  
  .menu-dropdown-export > * {
    overflow: hidden;
    // height: 45px;
    height: max-content;
    padding: 0px 10px;
    background: rgba(0, 0, 0, 0);
    white-space: nowrap;
    transition: 0.5s height cubic-bezier(0.73, 0.32, 0.34, 1.5),
      0.5s padding cubic-bezier(0.73, 0.32, 0.34, 1.5),
      0.5s margin cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s 0.2s color,
      0.2s background-color;
  }
  
  .menu-dropdown-export > *:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  

  .cstmdropdoen:not(:hover) > .menu-dropdown-export > * {
    visibility: hidden;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    color: rgba(25, 25, 25, 0);
    transition: 0.5s 0.1s height, 0.5s 0.1s padding, 0.5s 0.1s margin, 0.3s color,
      0.6s visibility;
    z-index: 99;
  }
  .menu-dropdown-export li {
    margin-bottom: 10px;
}
.menu-dropdown-export i.fa.iconcircle {
    margin-right: 6px;
}
ul.menu-dropdown-export span {
    color: #333;
    font-size: 14px;
    font-weight: bold;
}

ul.menu-dropdown-export span:hover {
  color: #fff;
}

.menu-dropdown-export i.fa.fa-file-excel-o.iconcircle {
    margin-top: 0px;
    margin-left: 0px!important;
}
.menu-dropdown-export li:hover{
    background: #3eb6c4;
}

/* //ndlevelcircle */
.exportanimatedcircle{
  -webkit-animation-duration:3s;
  animation-duration:3s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both
}

@-webkit-keyframes fadeInLeftcircle{
  0%{
      opacity:0;
      -webkit-transform:translateX(-200px);
      transform:translateX(-200px)}
  100%{
      opacity:1;
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
}
@keyframes fadeInLeftcircle{
  0%{
      opacity:0;
      -webkit-transform:translateX(-200px);
      -ms-transform:translateX(-200px);
      transform:translateX(-200px)}
  100%{
      opacity:1;
      -webkit-transform:translateX(0);
      -ms-transform:translateX(0);
      transform:translateX(0)
  }
}
.fadeInLeftcircle{
  -webkit-animation-name:fadeInLeftcircle;
  animation-name:fadeInLeftcircle;  
  z-index: 1;
}
/* //ndlevelgo */
.exportanimatedgo{
  -webkit-animation-duration:2s;
  animation-duration:2s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both
}

@-webkit-keyframes fadeInLeftgo{
  0%{
      opacity:0;
      -webkit-transform:translateX(-200px);
      transform:translateX(-200px)}
  100%{
      opacity:1;
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
}
@keyframes fadeInLeftgo{
  0%{
      opacity:0;
      -webkit-transform:translateX(-200px);
      -ms-transform:translateX(-200px);
      transform:translateX(-200px)}
  100%{
      opacity:1;
      -webkit-transform:translateX(0);
      -ms-transform:translateX(0);
      transform:translateX(0)
  }
}
.fadeInLeftgo{
  -webkit-animation-name:fadeInLeftgo;
  animation-name:fadeInLeftgo;  
  z-index: 1;
}
/* //ndlevelgoexcel */

.exportanimated{
  -webkit-animation-duration:1s;
  animation-duration:1s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both
}

@-webkit-keyframes fadeInLeftt{
  0%{
      opacity:0;
      -webkit-transform:translateX(-200px);
      transform:translateX(-200px)}
  100%{
      opacity:1;
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
}
@keyframes fadeInLeftt{
  0%{
      opacity:0;
      -webkit-transform:translateX(-200px);
      -ms-transform:translateX(-200px);
      transform:translateX(-200px)}
  100%{
      opacity:1;
      -webkit-transform:translateX(0);
      -ms-transform:translateX(0);
      transform:translateX(0)
  }
}
.fadeInLeftt{
  -webkit-animation-name:fadeInLeftt;
  animation-name:fadeInLeftt;  
  z-index: 1;
}



table.newTable tbody td {
  font-size: 9px!important;
}

table.newTable thead {
  // background: #015858;
  // background: -moz-linear-gradient(top, #408282 0%, #1a6868 66%, #015858 100%);
  // background: -webkit-linear-gradient(top, #408282 0%, #1a6868 66%, #015858 100%);
  // background: #3eb6c4;
  border-bottom: 1px solid #3eb6c4!important;
}

.bs-datepicker-body {
    background: #eceeee;
}

.card-title span.headngins::before {
    content: "" !important;
    display: inline-block!important;
    font: normal normal bolder 15px/1 "nucleo-icons" !important;
    font-size: inherit;
    text-transform: none;
    color: #258995;
    margin-left: -15px;
}

  @media (max-width: 991px) {
    #pdfTable {
      overflow-x: scroll;
      height: auto;
      min-height: auto;
  }
  bs-datepicker-container, bs-daterangepicker-container {
      transform: translate3d(12px, 237px, 0px)!important;
  }
  // .frowbtn button.ml-2.dilog.dilog1 {
  //     margin-top: 20px!important;
  // }
}

 @media (max-width: 768px) {
    .frowbtn button.ml-2.dilog.dilog1 {
      margin-top: 20px!important;
  }
 }

















 