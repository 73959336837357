@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
body {
    font-family: 'roboto', sans-serif;
     color: #444444;
   }
   
   
   a:hover {
     text-decoration: none;
   }
   
   h1, h2, h3, h4, h5, h6 {
    font-family: 'roboto', sans-serif;
   }
   p{
   color: #333;
       font-size: 15px;
       text-align: justify;
       line-height: 26px;
       margin-bottom: 10px;
   }
 
   html,
   body {
       margin: 0;
       padding: 0;
       box-sizing: border-box;
       scroll-behavior: smooth;
   }
   /* Navbar section */
   
   .topnav .nav {
       width: 100%;
       height: 65px;
       position: fixed;
       line-height: 65px;
       text-align: center;
       z-index: 999;
       background: #333;
       top:0;
   }
   
   .nav div.logo {
       float: left;
       width: auto;
       height: auto;
       padding-left: 3rem;
   }
   
   .nav div.main_list {
       height: 65px;
       float: right;
   }
   
   .nav div.main_list ul {
       width: 100%;
       height: 65px;
       display: flex;
       list-style: none;
       margin: 0;
       padding: 0;
   }
   
   .nav div.main_list ul li {
       width: auto;
       height: 65px;
       padding: 0;
       padding-right: 3rem;
   }
   
   .nav div.main_list ul li a {
       text-decoration: none;
       color: #fff;
       line-height: 65px;
       font-size: 16px;
       position: relative;
   }
   
   .nav div.main_list ul li a:hover {
       color: #ffdc2a;
       opacity: 0.8;
       transition: all 0.3s ease-in-out 0s;
   }
   .nav div.main_list ul li a:before {
       content: "";
       position: absolute;
       width: 0;
       height: 2px;
       bottom: 3px;
       top: 25px;
       background-color: #fff;
       visibility: hidden;
       width: 0px;
       transition: all 0.3s ease-in-out 0s;
   }
   .nav div.main_list ul li a:hover:before, .nav div.main_list ul li:hover > a:before, nav div.main_list ul li .active > a:before {
       visibility: visible;
       width: 25px;
   }
   .navTrigger {
       display: none;
   }
   nav.topnav.nav {
       -webkit-transition: all 0.4s ease;
       transition: all 0.4s ease;
       position: fixed;
       z-index: 9999;
       width: 100%;
   }
   
   img.cstm_logo {
       width: 160px;
       height: auto;
       padding-top: 15px;
   }
   img.btnplaystor {
       width: 25px;
       height: 25px;
   }
   .nav div.main_list ul li.no_border :before {
       content: "";
       position: absolute;
       width: 0;
       height: 0px;
       bottom: 0px;
       top: 25px;
       background-color: #fff;
       visibility: hidden;
       width: 0px;
       transition: all 0.3s ease-in-out 0s;
   }
   /* Media qurey section */
   
  
   
   
   /* Animation */
   /* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */
   
   .navTrigger {
       cursor: pointer;
       width: 30px;
       height: 25px;
       margin: auto;
       position: absolute;
       right: 30px;
       top: 0;
       bottom: 0;
   }
   
   .navTrigger i {
       background-color: #fff;
       border-radius: 2px;
       content: '';
       display: block;
       width: 100%;
       height: 4px;
   }
   
   .navTrigger i:nth-child(1) {
       -webkit-animation: outT 0.8s backwards;
       animation: outT 0.8s backwards;
       -webkit-animation-direction: reverse;
       animation-direction: reverse;
   }
   
   .navTrigger i:nth-child(2) {
       margin: 5px 0;
       -webkit-animation: outM 0.8s backwards;
       animation: outM 0.8s backwards;
       -webkit-animation-direction: reverse;
       animation-direction: reverse;
   }
   
   .navTrigger i:nth-child(3) {
       -webkit-animation: outBtm 0.8s backwards;
       animation: outBtm 0.8s backwards;
       -webkit-animation-direction: reverse;
       animation-direction: reverse;
   }
   
   .navTrigger.active i:nth-child(1) {
       -webkit-animation: inT 0.8s forwards;
       animation: inT 0.8s forwards;
   }
   
   .navTrigger.active i:nth-child(2) {
       -webkit-animation: inM 0.8s forwards;
       animation: inM 0.8s forwards;
   }
   
   .navTrigger.active i:nth-child(3) {
       -webkit-animation: inBtm 0.8s forwards;
       animation: inBtm 0.8s forwards;
   }
   
   @-webkit-keyframes inM {
       50% {
           -webkit-transform: rotate(0deg);
       }
       100% {
           -webkit-transform: rotate(45deg);
       }
   }
   
   @keyframes inM {
       50% {
           transform: rotate(0deg);
       }
       100% {
           transform: rotate(45deg);
       }
   }
   
   @-webkit-keyframes outM {
       50% {
           -webkit-transform: rotate(0deg);
       }
       100% {
           -webkit-transform: rotate(45deg);
       }
   }
   
   @keyframes outM {
       50% {
           transform: rotate(0deg);
       }
       100% {
           transform: rotate(45deg);
       }
   }
   
   @-webkit-keyframes inT {
       0% {
           -webkit-transform: translateY(0px) rotate(0deg);
       }
       50% {
           -webkit-transform: translateY(9px) rotate(0deg);
       }
       100% {
           -webkit-transform: translateY(9px) rotate(135deg);
       }
   }
   
   @keyframes inT {
       0% {
           transform: translateY(0px) rotate(0deg);
       }
       50% {
           transform: translateY(9px) rotate(0deg);
       }
       100% {
           transform: translateY(9px) rotate(135deg);
       }
   }
   
   @-webkit-keyframes outT {
       0% {
           -webkit-transform: translateY(0px) rotate(0deg);
       }
       50% {
           -webkit-transform: translateY(9px) rotate(0deg);
       }
       100% {
           -webkit-transform: translateY(9px) rotate(135deg);
       }
   }
   
   @keyframes outT {
       0% {
           transform: translateY(0px) rotate(0deg);
       }
       50% {
           transform: translateY(9px) rotate(0deg);
       }
       100% {
           transform: translateY(9px) rotate(135deg);
       }
   }
   
   @-webkit-keyframes inBtm {
       0% {
           -webkit-transform: translateY(0px) rotate(0deg);
       }
       50% {
           -webkit-transform: translateY(-9px) rotate(0deg);
       }
       100% {
           -webkit-transform: translateY(-9px) rotate(135deg);
       }
   }
   
   @keyframes inBtm {
       0% {
           transform: translateY(0px) rotate(0deg);
       }
       50% {
           transform: translateY(-9px) rotate(0deg);
       }
       100% {
           transform: translateY(-9px) rotate(135deg);
       }
   }
   
   @-webkit-keyframes outBtm {
       0% {
           -webkit-transform: translateY(0px) rotate(0deg);
       }
       50% {
           -webkit-transform: translateY(-9px) rotate(0deg);
       }
       100% {
           -webkit-transform: translateY(-9px) rotate(135deg);
       }
   }
   
   @keyframes outBtm {
       0% {
           transform: translateY(0px) rotate(0deg);
       }
       50% {
           transform: translateY(-9px) rotate(0deg);
       }
       100% {
           transform: translateY(-9px) rotate(135deg);
       }
   }
   nav.topnav .affix {
       padding: 0;
       background-color: #111;
       box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
   }
   /*////////////////slider top//////////////////////////////*/
   
   .carousel{
       background: #2f4357;
   }
   
   .carousel-indicators {
       justify-content: start!important;
       bottom:120px;
   }
   
   .carousel-item img {
       width: 100%;
   }
   .bgcurve{
       position: static!important;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       overflow: hidden;
       z-index: -100;
       width: 100%;
       opacity: 1;
       height: 100vh;
   }
   .bgcurve:after{
   content: '';
       width: 100%;
       position: absolute;
       height: 100vh;
       background-image: url('/assets/img/bg-white-curve.png');
       background-size: 100%;
       left: 0;
       top: 69.5vh;
       z-index: 2;
   }
   .banner-content h2{
       font-size: 45px;
       color: #fff!important;
       font-weight: 600;
       margin-bottom: 5px;
   }
   .banner-content h2 span {
       color: #ffdc2a;
       background: transparent;
   }
   .banner-content p{
       color: #ffdc2a;
       font-size: 24px;
       margin-bottom: 30px;
       font-style: italic;
   }
   
   .banner-graphicphone
   {
     width:280px;
     height:350px;
   }
   
   .smrightimgslider {
     width:450px;
     height:auto;  
   }
   .banner-content {
       text-align: left;
   }
   
   .mttopslider
   {
       margin-top:100px;
   }
   .slidetoptxt{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
   }
   .carousel-caption {
       top: 25%;
   }
   .lognbtn {
       color: #2e83c0;
       font-size: 1.09375vw;
       line-height: 1.09375vw;
       letter-spacing: 0.5px;
       text-transform: uppercase;
       font-weight: 600;
       padding: 10px;
       border-radius: 2.34375vw;
       outline: none;
       background-color: #fff;
       background-position: center;
       transition: background 0.4s;
       min-width: 11.625vw;
       border:none;
   }
   
   .lognbtn:hover{
    background-color: #2e83c0;
    color:#fff;
   }
   button:focus {
       outline: none;
       outline: none;
   }
   .all_main_heading h2 {
       font-weight: 600;
       font-size: 2.100vw;
       margin-bottom: 8px;
       color: #2e83c0;
   
   }
   .all_main_heading h3 {
       font-weight: 600;
       font-size: 1.375vw;
       margin-bottom: 10px;
   }
   
   .banner-content.bgoverlay {
       font-size: 45px;
       color: #ffdc2a;
       font-weight: 600;
       margin-bottom: 5px;
       background: #348ac7ba;
       padding: 10px;
       margin: 10px;
       border-radius: 4px;
       text-align:center;
       width:100%;
       height: fit-content;
   }
   .banner-content.bgoverlay h2 {
       font-size: 30px;
       color: #ffdc2a;
   }
   .banner-content.bgoverlay h2 {
       font-size: 30px;
       color: #ffdc2a;
   }
   
   .all_main_heading p{
     color: #333;
     font-size: 15px;
     text-align: justify;
     line-height: 26px;
   
   }
   .mrtop35
   {
     margin-top:35px;
   }
   .bgawards {
       padding: 20px 0;
       background-image: url('/assets/img/bg_blue.jpg');
       background-position: center center;
       background-size: cover;
       position: relative;
       background-attachment: fixed;
       margin-top:30px;
   }

   .socialicon{
       text-align:center;
   }
   .socialicon span{
       font-size: 34px;
       color: #fff;
       padding: 0px 15px;
   }
   p.awardstartxt {
       color: #fff;
       text-align: center;
       margin-top: 30px;
       margin-bottom: 15px;
   }
   .flexouter_award3 {
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
   }
   .boxcm {
       width: 31%;
       background: #fff;
       margin-bottom: 1rem;
       margin-right: 1em;
       box-shadow: 0 5px 24px 0 rgb(156 156 156);
       padding: 15px 0;
       border-radius: 6px;
       box-shadow: 0px 2px 15px rgb(0 0 0 / 35%);
       transition: 0.3s;
       cursor:pointer;
   }
   .boxcm:hover {
         box-shadow: rgb(45 45 45 / 5%) 0px 2px 2px, rgb(49 49 49 / 5%) 0px 4px 4px, rgb(42 42 42 / 5%) 0px 8px 8px, rgb(32 32 32 / 5%) 0px 16px 16px, rgb(49 49 49 / 5%) 0px 32px 32px, rgb(35 35 35 / 5%) 0px 64px 64px!important;
       transform: translate(0, -8px)!important;
        
   }
   
   .boxcm p {
       font-size: 14px;
       padding: 10px 30px;
       text-align: justify;
   }
   .boxcm i
   {
       background: #2e83c0;
       width: 40px;
       height: 40px;
       border-radius: 5px;
       margin-left: 30px;
       color: #fff;
       text-align: center;
       line-height: 40px!important;
       font-size: 22px;
   }
   .boxcm span.material-icons {
       background: #2e83c0;
       width: 40px;
       height: 40px;
       border-radius: 5px;
       margin-left: 30px;
       color: #fff;
       text-align: center;
       line-height: 40px!important;
       font-size: 22px;
   }
   .txt_white{
       color:#fff!important;
   }
   .txt_blue{
      color:#2e83c0!important; 
   }
   .text-left{
       text-align:left;
   }
   .our_visionsub h2 {
       text-align: center;
       font-weight: 600;
       font-size: 2.375vw;
       color: #fff;
   }
   .our_visionsub p{
       color: #fff;
       font-size: 16px;
       text-align: center;
   }
   .flex_centercentent{   
       display: flex;
       justify-content: center;
       align-items: initial;
       flex-direction: column;
       margin-top:10px;
   }
   .directirmsgflex {
       display: flex;
       flex-direction: row;
   }
   .imgcircle {
       overflow: hidden;
       width: 50px;
       height: 50px;
       border-radius: 50%;
       border: 1px solid #eee;
   }
   .outerboxmeter {
       box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
       padding: 30px;
       border-radius: 5px;
       background: #fff;
       transition: 0.5s;
       position: relative;
   }
   .smartmetersm {
       background: #fff;
       box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
       width: 200px;
       position: absolute;
       top: -75px;
       right: 22px;
       border-radius: 5px;
       padding: 5px 10px;
       transition: all ease-in-out 0.4s;
   }
   
   .smartmetersm:hover {
       transform: translate(0px, 8px)!important;
       box-shadow: rgb(45 45 45 / 5%) 0px 2px 2px, rgb(49 49 49 / 5%) 0px 4px 4px, rgb(42 42 42 / 5%) 0px 8px 8px, rgb(32 32 32 / 5%) 0px 16px 16px, rgb(49 49 49 / 5%) 0px 32px 32px, rgb(35 35 35 / 5%) 0px 64px 64px!important;
   }
   .hovertransform{
       transition: all ease-in-out 0.4s;
   }
   .hovertransform:hover{
       transform: translate(0, -8px)!important;
   }
   .dirname {
       padding-left: 15px;
   }
   .dirname h2 {
       font-weight: 600;
       margin-bottom: 0px;
       font-size: 20px;
       color: #2e83c0;
   }
   .dirname p {
       font-size: 14px;
       color: #333;
   }
   .bothboxouter p{
      font-size: 15px;
       color: #333;
   }
   .smartmetersm h2 {
       font-weight: 600;
       margin-bottom: 0px;
       font-size: 14px;
       color: #2e83c0;
   }
   .smartmetersm p {
     font-size: 12px;
       color: #333;
       margin-top: 5px;
   }
   .outerboxmeter{
       margin-right:60px;
       transition: all ease-in-out 0.4s;
   }
   
   .outerboxmeter:hover {
       transform: translate(0, -8px)!important;
       transition: all ease-in-out 0.4s;
   }
   
   .bgsoldimage{
       padding: 70px 0;
       background-image: url('/assets/img/soldimage.png');
       width: 100%;
       margin: 50px 0px;
   }
   /* .bgsoldimage:before
   {
       content: '';
       position: absolute;
       left: 0;
       top: 0;
       width: 100%;
       height: 100%;
       background-image: radial-gradient(circle at 48% 35%, rgba(0,0,0,0),#000000);
       z-index: 1;
   } */
   .outermetersold {
       display: flex;
   }
   .submetersold {
       flex-grow: 1;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       margin: 0px 5px;
       border-right: 3px solid #fff;
      transition: 0.5s;
   }
   .submetersold:last-child {
       border-right: none;
   }
   .submetersold span {
      width: 70px;
       height: 70px;
       background: #fff;
       border-radius: 50%;
       text-align: center;
       line-height: 70px;
       font-size: 38px;
       color: #2e83c0;
   }
   h2.counter {
       color: #2e83c0;
       font-weight: 600;
       margin-top: 8px;
       font-size: 25px;
       padding-bottom: 0px;
       /* margin-bottom: 15px; */
   }
   .countermap .meters {
       margin-top: 15px;
   }
   .submetersold p {
       color: #d9d6d6;
       font-size: 19px;
   }
   .allsubtxtsmart i {
       background: #f1f3f3;
       width: 40px;
       height: 40px;
       color: #2e83c0;
       line-height: 40px;
       border-radius: 50%;
       line-height: 40px;
       text-align: center;
       margin-right: 20px;
   }
   .allsubtxtsmart li {
       list-style: none;
       font-size: 17px;
       margin-bottom: 12px;
   }
   ul.allsubtxtsmart {
       margin: 0px!important;
       padding: 0px;
   }
   h3.cstmborder {
       margin-bottom: 20px;
   }
   .cstmborder::before {
       content: "";
       width: 40px;
       height: 2px;
       display: inline-block;
       background: #2e83c0;
       margin: 5px 8px;
   }
   .cstmborder::after {
       content: "";
       width: 40px;
       height: 2px;
       display: inline-block;
       background: #2e83c0;
        margin: 5px 8px;
   }
   .align_center_txt{
       display: flex;
       justify-content: center;
       align-items: center;
   }
   
   
   .margintop90{
     margin-top:90px; 
   }
   
   #hero .animated {
     animation: up-down 2s ease-in-out infinite alternate-reverse both;
   }
   
   @-webkit-keyframes up-down {
     0% {
       transform: translateY(10px);
     }
     100% {
       transform: translateY(-10px);
     }
   }
   
   @keyframes up-down {
     0% {
       transform: translateY(10px);
     }
     100% {
       transform: translateY(-10px);
     }
   }
   /*--------------------------------------------------------------
   # Disable AOS delay on mobile
   --------------------------------------------------------------*/
   @media screen and (max-width: 768px) {
     [data-aos-delay] {
       transition-delay: 0 !important;
     }
   }
   @media (max-width: 991px) {
   
     #hero .animated {
       -webkit-animation: none;
       animation: none;
     }
   }
     .solutionflexsub {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       background: #fff;
       margin-bottom: 20px;
       font-size: 14px;
       box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
   }
   .solutionflexsub i {
      width: 60px;
       height: 60px;
       background: #2e83c0;
       border-radius: 50%;
       text-align: center;
       line-height: 60px;
       font-size: 38px;
       color: #fff;
       margin-top: -30px;
   }
   .solutionflexsub p{
       text-align: center;
       padding: 20px;
   }
   /*********************************************** timelineavon ***************************/
   #timelineavon {
     width: 80%;
     margin: 20px auto;
     position: relative;
   }
   #timelineavon:before {
     content: '';
     display: block;
     position: absolute;
     left: 50%;
     top: 0;
     margin: 0 0 0 -1px;
     width: 1px;
     height: 100%;
     background: rgb(17 16 16 / 19%);
   }
   #timelineavon article {
     width: 100%;
     margin: 0 0 0px 0;
     position: relative;
   }
   #timelineavon article:after {
     content: '';
     display: block;
     clear: both;
   }
   #timelineavon article div.inner {
     width: 40%;
     float: left;
     margin: 5px 0 0 0;
     border-radius: 6px;
   }
   #timelineavon article div.inner span.date {
     display: block;
       width: 50px;
       height: 50px;
       padding: 5px 0;
       position: absolute;
       top: 0;
       left: 50%;
       margin: 0 0 0 -25px;
       border-radius: 100%;
       font-size: 12px;
       font-weight: 900;
       text-transform: uppercase;
       background: #2e83c0;
       color: rgba(255,255,255,0.5);
       border: 3px solid #fff;
       box-shadow: 0 5px 15px 0 rgb(97 85 85 / 55%);
       line-height: 34px;
       text-align: center;
   }
   .avonhistory {
       background: #f3f3f3;
       margin: 30px 0px;
   }
   .avonhistory h2{
       padding-top:18px;
   }
   
   #timelineavon article div.inner span.date span.year {
     font-size: 12px;
     color:#fff;
   }
   #timelineavon article div.inner h2 {
     padding: 12px;
     margin: 0;
     background: #fff;
     color: #333;
     font-size: 14px;
     text-transform: capitalize;
     position: relative;
     box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
     text-align:justify;
     /* word-break: break-all; */
     min-height: 55px;
     line-height:24px;
     
   }
   #timelineavon article div.inner h2:after {
       content: '';
       position: absolute;
       top: 20px;
       right: -8px;
       width: 18px;
       height: 18px;
       -webkit-transform: rotate(-45deg);
       background: #fff;
   }
   #timelineavon article div.inner p {
     padding: 15px;
     margin: 0;
     font-size: 14px;
     background: #fff;
     color: #656565;
     border-radius: 0 0 6px 6px;
   }
   #timelineavon article:nth-child(2n+2) div.inner {
     float: right;
   }
    #timelineavon article:nth-child(2n+2) div.inner h2:after {
     left: -7px;
   }
    #timelineavon article:nth-child(1) div.inner h2 {
     background: #fff;
   }
   .avonhistory {
       padding-bottom: 30px;
   }
   /********************************************awardsslider ******************************/
   .subblue span {
       color: #2e83c0;
       font-weight: 600;
   }
   .introduce img.img-fluid {
       width: 100%;
       height: 320px;
   }
   
   /********************************************our client ******************************/
   
   .ourclientouter .client-list {
       display: flex;
       align-items: center;
       flex-wrap: wrap;
   }
   .ourclientouter .client-list .item {
       width: 20%;
       display: flex;
       justify-content: center;
       flex-wrap: wrap;
   }
   .clientcard:last-child {
       margin-bottom: 0;
   }
   .imgcard {
       width: 14.0625vw;
       height: 14.0625vw;
       border-radius: 2.34375vw;
       box-shadow: 0 5px 15px 0 rgb(97 85 85 / 55%);
       position: relative;
       background: #fff;
       margin: .9375vw 10px;
       z-index: 1;
       transition: all ease-in-out 0.4s;
   }
   .imgcard:hover {
       transform: translate(0, -8px)!important;
   }
   .imgcard .brand-logo.typ-big {
       width: 76%;
   }
   .imgcard .brand-logo {
       position: absolute;
       left: 0;
       right: 0;
       top: 50%;
       width: 55%;
       margin: 0 auto;
       transform: translateY(-50%);
   }
   .imgcard img.img-fluid {
       position: absolute;
       left: 0;
       right: 0;
       top: 53%;
       width: 90%;
       margin: 0 auto;
       transform: translateY(-50%);
   }
   
   /***************************************** contact us **********************/
   
   .bgcontactus {
       padding: 30px 0;
       background-image: url('/assets/img/bgcontact_us.png') no-repeat;
       width: 100%;
       margin: 30px 0px;
       position:relative;
   }
   .bgcontactus:before {
       content: "";
       /* background: #2e83c096; */
       position: absolute;
       bottom: 0;
       top: 0;
       left: 0;
       right: 0;
   }
   .contactus .form-control {
       border-radius: 2.34375vw;
       height: 45px;
       padding-left: 24px;
   }
   .contactus textarea {
      border-radius: 2.34375vw;
      height: auto!important;
   }
   .bgbtncontact
   {
       background: #0b3d60;
       color:#fff;
   }
   .bgbtncontact:hover {
       background: #ffdc2a;
       color: #fff;
       transition: 0.3s;
   }
   .hqinfo {
       display: flex;
       flex-direction: column;
   }
   .hqinfoaddress
   {
       margin-bottom: 10px;
   }
   .hqinfoaddress i {
     font-size: 14px;
       background: #fff;
       color: #0b3d60;
       float: left;
       width: 30px;
       height: 30px;
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 4px;
       transition: all 0.3s ease-in-out;
       text-align: center;
       line-height: 30px!important;
       margin-right: 12px;  
   }
   .socialiconfooter ul li i{
     font-size: 15px;
       background: #2e83c0;
       color: #fff;
       float: left;
       width: 40px;
       height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 50px;
       transition: all 0.3s ease-in-out;
       text-align: center;
       line-height: 40px!important;
       margin-right: 20px;
       margin-bottom: 30px;  
   }
   ul {
       margin: 0px;
       padding: 0px;
   }
   li{
       list-style:none;
   }
   .socialiconfooter ul {
       list-style:none;
       margin-top:20px;
   }
   .hqinfoaddress i:hover {
       background: #ffdc2a;
       color: #fff;
       transition: 0.3s;
       transform: rotate(180deg);
   }
   
   .socialiconfooter i:hover {
       background: #ffdc2a;
       color: #fff;
       transition: 0.3s;
       transform: rotate(180deg);
   }
   .iconcontact p {
       color: #fff;
       font-size: 12px;
       margin-bottom:0px;
       
   }
   .artissectionsectioncle {
     min-height: 50vh;
     margin-bottom: 50px;
   }
    /***********************************threeinfographics **************************/
   
   .threepointouter {
       display: flex;
       flex-direction: revert;
       justify-content: center;
       align-items: center;
   }
   
   .threepoint {
        width: 30%;
       /* height: 14.0625vw; */
       border-radius: 2.34375vw;
       box-shadow: 0 5px 15px 0 rgb(97 85 85 / 55%);
       position: relative;
       background: #fff;
       margin: .9375vw 10px;
       /* z-index: 1; */
       transition: all ease-in-out 0.2s;
   }
   
   .threepoint:hover {
       transform: translate(0, -8px)!important;
       transition: all ease-in-out 0.3s;
   }
   .producttxtthree {
       border: 1px solid #d5d5d5;
       padding: 20px;
       border-radius: 5px;
   }
   .producttxtthree i{
       font-size: 28px;
       color: #2e83c0;
       text-align: center;
       margin: 0px auto;
       display: block;
   }
   .producttxtthree p {
      text-align: center;
       color: #333;
       font-size: 16px;
   }
   .borderproduct{
     
       background: #2e83c0;
       width: 60px;
       height: 60px;
       text-align: center;
       line-height: 60px;
       color:#fff;
       margin: -2px;
       font-weight: bold;
   }
   .borderproduct.proone {
       border-radius: 48px 0px 152px;
       float: left;
   }
   .producttxtthree {
       border: 1px solid #f5f3f3;
       margin: 25px;
       padding: 20px;
       border-radius: 5px;
       z-index: 2;
   }
   
   .borderproduct.protwo {
       border-radius: 3px 34px 2px 108px;
       float: right;
   }
   
   .borderproduct.prothree {
      border-radius: 62px 0px 14px!important;
       float: right!important;
       position: absolute;
       bottom: 0px;
       right: 0px;
   }
   
   .circleouterIRDA {
       background: #fff;
       border-radius: 2.34375vw;
       box-shadow: 0 5px 15px 0 rgb(156 156 156 / 20%);
       border-radius: 50%;
       text-align: center;
       color: #333;
   }
   .circleouterIRDA {
       top: 20px;
       margin: 0px auto;
       position: absolute;
       left: 0;
       right: 0;
       top: 50%;
       width: 130px;
       height: 130px;
       margin: 0 auto;
       transform: translateY(-50%);
       border: 5px solid #2e83c029;
       transition: all ease-in-out 0.3s;
   }
   
   .circleouterIRDA:hover {
        /* transform: translate(0, -6px)!important; */
       transition: all ease-in-out 0.3s;
   }
   
   
   .circleDLMS p {
       font-size: 14px;
       line-height: 16px;
       text-align: center;
       position: absolute;
       left: 0;
       right: 0;
       top: 50%;
       transform: translateY(-50%);
       font-weight: 600;
   }
   /**************************footer img *******************************************/
   
   .bgfootercurve{
       padding: 20px 0px;
        background-image: url('/assets/img/bottom_footer.jpg');
       width: 100%;
       position: relative;
       /* background: #333; */
   
   }
   .footerusefullink a {
       color: #fff;
       font-size: 14px;
   }
   .footerusefullink a:hover
   {
     color: #fff; 
   }
   .footerusefullink a i
   {
       padding-right: 6px;
       color: #dad8d8;
       font-size: 11px;
   }
   .footerusefullink li
   {
       margin-top: 5px;
       color:#fff;
   }
   
   .footerusefullink li:hover
   {
       padding-left: 4px;
       transition: 0.3s;
   }
   .footerusefullink {
        transition: 0.3s;
   }
   
   p.copyright {
       background: linear-gradient(to left, #26cccc9e 13%, #8352d2ad 83%);
       color: #eee;
       margin: 0px;
       text-align: center;
       padding: 4px;
     
   }
   
   /*BOTTOM TO - TOP*/
   .scrollup{
       position: fixed;
       right: 10px;
       bottom: 10px;
       cursor: pointer;
       width: 40px;
       height: 40px;
       background-color: #ffdc2a;
       -webkit-border-radius: 60px;
       -moz-border-radius: 60px;
       border-radius: 60px;
       text-align: center;
       line-height: 40px;
       color: #3498db; 
       box-shadow: 0 5px 15px 0 rgb(97 85 85 / 55%);
       transition: all ease-in-out 0.4s;
       z-index: 2;
   }
   
   .scrollup:hover{
       transition: 0.3s;
       background-color: #3498db;
       color: #ffdc2a; 
       transform: translate(0, -8px)!important;
       transition: all ease-in-out 0.4s;
   }
   .countermap {
       bottom: 10px;
       margin-bottom: 24px;
       text-align: left;
       position: absolute;
       background: #fff;
       box-shadow: 5px 5px 25px 0 rgb(46 61 73 / 20%);
       border-radius: .375rem;
       -moz-transition: all .3s ease;
       transition: all .3s ease;
       border: 1px solid #dbe2e8;
       padding: 15px;
       float: right;
       right: 0;
       border-right: 3px solid #2e83c0;
   /* border-left: 3px solid #2e83c0; */
       border-bottom: 3px solid #2e83c0;
   }
   .mapavon {
       position: relative;
   }
   .meters h2.counter {
       color: #2e83c0;
       margin-bottom: 0px;
       font-size: 22px;
       text-align: center;
   }
   .meters p {
       text-align: center;
       margin-top: 0px;
       font-size: 14px;
   }
   
   /************************************** loginpage *****************/
   .input-icons i{
       position: absolute;
       font-size: 12px;
   }
   i fas fa-eye.fa_right {
       position: absolute;
       top: 23px !important;
       color: #3574bd !important;
   }
   
   .topsub_msg h2
   {
       color: #333!important;
       font-size: 18px; 
       text-align: left; 
       margin-top: 20px;
       margin-bottom: 20px;
       font-weight: 600; 
   }
   .form-group.input-rounded .form-control {
       border-radius: 20px!important;
       padding-left: 40px!important;
       height: 42px!important;
       background: #fff;
       border: 1px solid #b1acac;
   }
   
   
   .icon {
       padding: 13px 20px!important;
       color: #3574bd!important;
       min-width: 20px;
       text-align: center;
   }
   label.porgotpswtxt {
       margin-top: 3px;
       cursor:pointer;
       font-size:14px;
   }
   .checkbox label {
       font-size: 14px;
       cursor:pointer;
       line-height: 1.5;
   }
   .forgotpasswordtxt {
       font-size: 0.875rem;
       margin-top: -16px;
       font-weight: 500;
   }
   .btn{
         border-radius: 20px!important;
         background:#3574bd!important;
         font-size: 16px!important;
   }
   .vertical-separator_hr {
       width: 100%;
       border-top: 1px solid #3574bd;
   }
   .justify-content-center.venture_or {
       display: flex;
       margin-bottom: 15px;
   }
   .justify-content-center {
       justify-content: center!important;
   }
   .vertical-separator {
       display: flex;
       align-items: center;
       margin-top: 20px;
   }
   
   .vertical-separator_or {
       padding-left: 10px;
       padding-right: 10px;
   }
   .flexrow {
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 50%;
   }
   
   .flexrow a{
       color: #fff!important;
       background: #3574bd;
       width: 30px;
       height: 30px;
       margin: 5px;
       display: flex;
       justify-content: center;
       align-items: center;
       border-radius: 50%;
   }
   .flexrow a:hover{
     color: #e8e6e6!important;
   }
   .right_imglogin {
      display: flex;
       -ms-flex-pack: center;
       justify-content: center;
       -ms-flex-align: center;
       align-items: center;
       width: 300px;
       margin: 0px auto;
       height: 100%
   }
   .right_imglogin img {
       width: 300px;
   }
   i.fa.fa-eye-slash.fa_right {
       position: absolute;
       top: 23px!important;
       color: #3574bd!important
   }
   .forgotpsw {
       display: flex;
       justify-content: space-between;
       align-items: center;
       margin-bottom: 10px;
   }
   .porgotpswtxt{
       margin-top: -17px;
       margin-right: 10px;
       font-size: 0.875rem;
       font-weight: 500;
   }
   
   .input-icons i {
     position: absolute;
   }
   
    .input-icons { 
       width: 100%; 
       position: relative;
     } 
   
   .icon {
     padding: 10px;
     color: #b31232;
     min-width: 20px;
     text-align: center;
   }
   
   .errspan {
     float: right;
     margin-right: 6px;
     margin-top: -20px;
     position: relative;
     z-index: 2;
     color: red;
   }
   
   .input-field {
     width: 100%;
     padding: 10px;
     text-align: center;
   }
   
   .p-viewer {
       z-index: 9999;
       position: absolute;
       top: 30%;
       right: 50px;
       cursor: pointer;
   }
   .modal-header {
       display: flex;
       -ms-flex-align: start;
       align-items: flex-end;
       -ms-flex-pack: justify;
       justify-content: flex-end;
       padding: 0rem 20px;
       border-bottom: none;
       cursor: pointer;
       color: #3574bd;
       font-size: 29px;
   }
   .modal-header:hover {
       color:#333;
   }
   .popuplogo img {
       width: 200px;
       display: -ms-flexbox;
       display: flex;
       -ms-flex-direction: row;
       flex-direction: row;
       margin-top: 20px;
       margin-bottom: -5px;
   }
   .modal {
       background: #212529ab;
   }
   
   .modal-backdrop.show {
       opacity: 0;
   }
   .inner_wraper {
       margin-bottom: 30px;
       margin-top: 15px;
   }
   .opt_login {
       color: #2e83c0!important;
       font-size: 14px;
       text-align: left;
       margin-top: 10px;
       margin-bottom: 10px;
       font-weight: 600;
       text-decoration:underline;
   }
   /*********************************** for modalpopup**********************************/
     .modal-contentradius {
       background: #fff;
       /* text-align: center; */
       border: none;
       border-radius: 30px;
       box-shadow: 15px 15px 30px rgb(0 0 0 / 30%);
       position: relative;
   }
   .modal-contentradius:before{
       content: "";
       background: #2e83c0;
       width: 100%;
       height: 60px;
       border-radius: 5px 5px 50% 50%;
       box-shadow: 0 10px 15px rgba(0,0,0,0.2);
       position: absolute;
       top: 0;
       left: 0;
       z-index: 0;
   }
   button.mat-menu-trigger.icon.iconmenuheight{
       height: 36px!important;
   }
   .iconmenuheight .mat-icon {
       vertical-align: -8px!important;
   }
   button.btn.btn-primary.btn-block.otpbtn {
       background: #fff!important;
       border: 3px solid #447DF7;
       color: #333!important;
   }
   .close_popupbtn{
       color: #fff!important;
       background-color: #2e83c0;
       font-size: 35px;
       text-shadow: none;
       line-height: 16px;
       height: 35px;
       width: 35px;
       border-radius: 50%;
       opacity: 1;
       position: absolute;
       right: -2px;
       top: -2px;
       z-index: 1;
       transition: all 0.3s;
   }
   
   button.dilog4.close_popupbtn {
       background: #ffdc2a;
   }
   
   button.dilog4.close_popupbtn:hover {
       color: #fff;
       background-color: #2e83c0;
       box-shadow: 0 0 10px rgb(0 0 0 / 40%);
   }
   .close_popupbtn:hover{
    color: #fff;
       background-color: #ffdc2a;
       box-shadow: 0 0 10px rgb(0 0 0 / 40%);
   }
   .modal_txt h1 {
       color: #fff;
       font-size: 22px;
       text-align: center;
       font-weight: 600;
       margin-top: 25px;
   }
   .modal_contentall {
       margin-top: 20px;
       color: #333!important;
   }
   .topsub_msg p {
       color: #333;
       font-size: 16px;
       margin-top: -10px;
   }
   .topsub_msg {
       margin-bottom: 15px;
   }

   
   .card-heighscroll{
       max-height: 507px;
       min-height: 500px;
       overflow-y: auto;
   }
   
   .iconbox {
       width: 40px;
       height: 40px;
       border-radius: 0.42rem;
       margin-right: 10px;
       /* padding: 12px; */
       background: #F5F5FF;
       line-height: 40px;
       /* margin-bottom: 5px; */
       text-align: center;
   }
   .implink h5 {
       font-size: 14px!important;
       font-weight: 500!important;
       color: #474D58;
       margin-bottom: 0;
       font-weight: 600!important;
   }
   .importantlink.single_plan:hover {
       background: #eee;
       margin-left: 5px;
       transition: all ease-in-out 0.3s;
       color: #fff;
       border-radius: 5px;
   }
   .bgorgbox{
        background: #FFE2E5;
   }
   .bggreenbox{
       background: #C9F7F5
   }
   .bgobbox{
        background: #EEE5FF;
   }
   
   .bgobboxyellow {
       background: #ffde9f;
   }
   .mmb3 {
       padding-bottom: 20px;
   }
   .plan_left:hover {
       transition: all ease-in-out 0.4s;
       cursor: pointer;
       box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
       background: #fff;
       width: 100%;
       color:#13b9cd;
   }
   .flex-rww i {
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       width: 150px;
       height: 43px;
       background: #13b9cd1f;
       margin-bottom: 10px;
       border-radius: 41px;
   }
   .flex-rww span {
       font-size: 14px;
   }
   
   /* ---------------------------------careers-------------------- */
   .login-container{
       margin-top: 1%;
       margin-bottom: 1%;
   }
   .login-form-1{
       padding: 2%;
       box-shadow: 0 5px 8px 0 rgb(0 0 0 / 49%), 0 9px 26px 0 rgb(0 0 0 / 40%);
       border: 20px solid #2e83c0;
       border-radius: 30px 0px 0px 30px;
   }
   .login-form-1 h3{
       text-align: left;
       color: #333;
   }
   .login-form-2{
       padding: 2%;
       background: #2e83c0;
       box-shadow: 0 5px 8px 0 rgb(0 0 0 / 49%), 0 9px 26px 0 rgb(0 0 0 / 40%);
       border-radius: 0px 30px 30px 0px;
   }
   .login-form-2 h3{
       text-align: center;
       color: #fff;
   }
   .login-container form{
       padding: 2%;
   }
   .btnSubmit
   {
       width: 50%;
       border-radius: 1rem;
       padding: 1.5%;
       border: none;
       cursor: pointer;
   }
   .login-form-1 .btnSubmit{
       font-weight: 600;
       color: #fff;
       background-color: #2e83c0;
   }
   .login-form-2 .btnSubmit{
       font-weight: 600;
       color: #2e83c0;
       background-color: #fff;
       font-size: 18px;
       line-height: 18px;;
   }
   .login-form-2 .ForgetPwd{
       color: #fff;
       font-weight: 600;
       text-decoration: none;
   }
   .login-form-1 .ForgetPwd{
       color: #2e83c0;
       font-weight: 600;
       text-decoration: none;
   }
   
   .intrvwLI li i {
       font-weight: 400;
       font-size: 22px!important;
       line-height: 40px !important;
       color: #2e83c0!important;
       margin-left: 1em;
       letter-spacing: 1px;
       color: #fff;
       font-size: 25px;
       padding-right: 15px;
       padding-right: 30px;
   }
   textarea#messagecv {
       border-radius: 30px;
   }
   .intrvwLI li {
       font-size: 16px;
       font-weight: bold;
       margin-top: 10px;
       color:#333;
   }
   ul.intrvwLI {
       margin-bottom: 20px;
   }
   .interviwform input.form-control {
       margin-bottom: 5px;
       border-radius: 40px;
       background: #fff!important;
       padding-left:15px;
   }
   .interviwform mat-select#mat-select-0 {
       /* margin-bottom: 5px; */
       border-radius: 40px!important;
   }
   .txtwhiteerror.invalid-feedback {
       width: max-content;
       margin-top: 0.25rem;
       font-size: 80%;
       background-color: #fff5f7;
       border: 1px solid #ffd7d6;
       padding: 1px 8px;
       border-radius: 5px;
       display: block;
       margin-left: 8px;
   }
   p.sharecv {
      color: #fff;
       font-size: 12px;
       text-align: justify;
       line-height: 16px!important;
   }
   p.sharecv a{
       color: #fff;
   }
   .mtb15
   {
       margin-top: 15px!important;
       margin-bottom: 15px!important;
       color: #fff!important;
   }
   .intrvwLI li:hover {
       padding-left: 4px;
       transition: 0.3s;
   }
   #branches .counter {
       color: #333;
       background: linear-gradient(to right bottom,#fff 50%, #f9f9f9 51%);
       text-align: center;
       width: auto;
       padding: 20px 0 0;
       margin: 0 auto;
       border-radius: 50px 0;
       box-shadow: 0 0 15px -5px rgb(0 0 0 / 30%);
       overflow: hidden;
       margin-top: 14px;
   }
   .counter .counter-icon{
       font-size: 45px;
       margin: 0 0 10px;
   }
   .counter h3{
       font-size: 16px;
       font-weight: 700;
       text-transform: capitalize;
       margin: 0 0 20px;
   }
   .counter .counter-value{
       color: #fff;
       background: linear-gradient(to right bottom, #FD8ED2, #F14997);
       font-size: 26px;
       font-weight: 700;
       line-height: 40px;
       padding: 7px 0 3px;
       display: block;
   }
   .counter.blue{ color: #333; }
   .counter.blue .counter-value{ background: linear-gradient(to right bottom, #2e83c0, #0a4f6b); }
   
   @media screen and (max-width:990px){
       .counter{ margin-bottom: 40px; }
   }
   
   .row.flex_branches {
       display: flex;
       -ms-flex-direction: revert;
       flex-direction: revert;
       -ms-flex-pack: center;
       justify-content: center;
       -ms-flex-align: center;
       align-items: center;
       margin-top: 40px;
       margin-bottom: 30px;
       padding-bottom: 25px;
   }
   
   #branches {
       margin-bottom: 50px;
       padding-top: 30px;
       padding-bottom: 30px;
   }
   
   #demo {
       height:100%;
       position:relative;
       overflow:hidden;
     }
     
     
     .green{
       background-color:#6fb936;
     }
       .thumb{
           margin-bottom: 30px;
       }
       
       .page-top{
           margin-top:85px;
       }
   
        
     img.zoom {
         width: 100%;
         height: 200px;
         border-radius:5px;
         object-fit:cover;
         -webkit-transition: all .3s ease-in-out;
         -moz-transition: all .3s ease-in-out;
         -o-transition: all .3s ease-in-out;
         -ms-transition: all .3s ease-in-out;
     }
   
     h3.lblpr {
       color: #333;
       font-size: 16px;
       margin-bottom: 15px;
   }
   .our_productU li {
       font-size: 13px;
       color: #333;
   }
   .box_pr h2 {
       font-size: 16px;
       text-align: center;
   }
   .our_productU {
       display: flex;
       flex-direction: row;
       justify-content: center;
       margin-bottom: 10px;
       margin-top: 10px;
   }
   .box_pr p {
       text-align: center;
   }
   .box_pr {
       /* width: 31%; */
       width:100%;
       background: #fff;
       margin-bottom: 1rem;
       margin-right: 1em;
       box-shadow: 0 5px 24px 0 rgb(156 156 156);
       padding: 15px;
       border-radius: 2px;
       border-bottom: 2px solid #2e83c0;
       transition: all ease-in-out 0.3s;
   }
   .box_pr:hover {
         transform: translate(0, -8px)!important;
       transition: all ease-in-out 0.3s;
   }
   
   .ourbranchAll h3 {
       text-align: center;
       margin-top: 30px;
       font-weight: 600;
       color: #333;
       font-size: 16px!important;
   }
   .topspacemodelgrip {
       margin-top: 54px;
   }
   
   .add_rowEgrid {
       top: 0px;
       z-index: 9999;
       color: #fff;
       font-size: 16px;
       position: absolute;
       left: 50%;
       transform: translate(-50%, 50%);
       font-weight: 600;
   }
   .modal-contentradius.gridinnerpage:before {
       content: "";
       background: #3eb6c4;
       width: 100%;
       height: 48px;
       border-radius: 5px 5px 50% 50%;
       box-shadow: 0 10px 15px rgb(0 0 0 / 20%);
       position: absolute;
       top: 0px;
       left: 0;
       z-index: 0;
   }
   .mat-checkbox-checked.mat-accent .mat-checkbox-background {
       background-color: #3eb6c4;
   }
   .mat-list-base[_ngcontent-ibs-c495] {
       width: 30%;
   }
   
   .fulladdM{
       background: #b7c6c747;
       transition: all ease-in-out 0.4s;
   
   }
   .iconaction
   {
       color:#3eb6c4
   }
   .fulladdM:hover
   {
       margin-top: 5px;
       transition: all ease-in-out 0.4s;
       cursor: pointer;
       border-bottom: 1px solid #3eb6c4!important;
   }
   .listaddinfogrid_bg
   {
       border: 1px solid #d0d8da7a;
       margin-top: 85px;
       margin-right: 55px;
   }
   
   .listactionspc{
       margin-top:70px;
       margin-right: 50px;
   }
   .close_popupbtn .mat-icon {
       color: #fff!important;
   }
   .listaddinfogrid
   {
       width: 50%;
       border: 1px solid #d0d8da7a;
       transition: all ease-in-out 0.4s;
   }
   .listaddinfogridfull
   {
       width: 100%;
       border: 1px solid #d0d8da7a;
       transition: all ease-in-out 0.4s;
   }
   .listaddinfogridfull:hover
   {
       background: #3eb6c4;
       color: #FFE!important;
       transition: all ease-in-out 0.4s;
       border-radius: 50px
   
   }
   .listaddinfogrid:hover
   {
       background: #3eb6c4;
       color: #FFE!important;
       transition: all ease-in-out 0.4s;
       border-radius: 50px
   
   }
   .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
       background-color:#3eb6c4
   }
   
   .mat-step-icon {
       height: 30px;
       width: 30px;
   }
   
   span.has-float-label {
       margin-top: 10px;
       width:100%;
   }
   .mat-horizontal-stepper-header .mat-step-icon {
       box-shadow: 0 4px 10px 0 rgb(0 0 0 / 25%);
   }
   .has-float-label .form-control {
       border: 1px solid #3eb6c4!important;
   }
   .has-float-label.form-control
   {
   width:100%;
   padding-left: 30px!important;
   }
   .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
       color: rgb(19 185 205);
   }
   .mat-tab-labels {
       border-bottom: 2px solid #dab912!important;
       background: #13b9cd2e!important;
       border-radius: 6px;
   }
   
   .mat-tab-label
   {
    border-right: 1px solid #ced3d4!important;
    height: 40px!important;
    width: 100%!important;
   }
   
   /* https://codepen.io/tonycorp/pen/LRdpYZ?html-preprocessor=pug */
   .has-float-label {
       display: block;
       position: relative;
     }
     .has-float-label label,
     .has-float-label > span {
       position: absolute;
       cursor: text;
       font-size: 75%;
       opacity: 1;
       -webkit-transition: all 0.2s;
       transition: all 0.2s;
       top: -0.5em;
       left: 0.75rem;
       z-index: 3;
       line-height: 1;
       /*padding: 1px 1px;*/
       font-weight: bold;
       background: #fff;
     }
     .has-float-label label::after,
     .has-float-label > span::after {
       content: " ";
       display: block;
       position: absolute;
       background: #fff;
       height: 2px;
       top: 50%;
       left: -0.2em;
       right: -0.2em;
       z-index: -1;
     }
     .has-float-label .form-control::-webkit-input-placeholder {
       opacity: 1;
       -webkit-transition: all 0.2s;
       transition: all 0.2s;
     }
     .has-float-label
       .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
       opacity: 0;
     }
     .input-group .has-float-label {
       display: table-cell;
     }
     .input-group .has-float-label .form-control {
       border-radius: 0.25rem;
       padding-left: 28px;
     }
     .input-group .has-float-label:not(:last-child),
     .input-group .has-float-label:not(:last-child) .form-control {
       border-bottom-right-radius: 0;
       border-top-right-radius: 0;
       border-right: 0;
     }
     .input-group .has-float-label:not(:first-child),
     .input-group .has-float-label:not(:first-child) .form-control {
       border-bottom-left-radius: 0;
       border-top-left-radius: 0;
     }
     .alert-danger {
       background-color: #fff!important;
       text-align: left!important;
       border: none!important;
       color: #dc3545!important;
       font-weight: normal!important;
       margin-top: 5px!important;
       padding: 0px!important;
   }
   
     .mat-icon {
       font-size: 18px!important;
       color: #13b9cd!important;
   }
   .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
       background: #2db1c1a6;
   }
   .bs-datepicker-head button.current {
       color: #000;
       font-weight: 600;
   }
   .bs-datepicker-head button.next, .bs-datepicker-head button.previous {
       color: #fff;
       background: #3eb6c4;
   }
   .bs-datepicker-head button.current {
       color: #fff;
       font-weight: 600;
       background: #3eb6c4;
   }
   .o_b1 {
       background: #13b9cd;
       text-align: center;
   }
   .o_b1.mat-icon {
       background-repeat: no-repeat;
       display: inline-block;
       fill: currentColor;
       height: 32px;
       width: 30px;
       /* background: #7da9b3; */
       border-radius: 50px;
       color: #ffffff!important;
       line-height: 24px;
       text-align: center;
   }
   .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
       background-color: #13b9cd;
   }
   
   .mat-radio-label {
       justify-content: center!important;
   }
   .dilog1 .mat-icon {
       color: #fff!important;
   }
   .meteractivitychkouter {
       display: grid;
       flex-direction: row;
       justify-content: left;
       align-items: center;
   }
   
   thead.tablebghead {
       background-color: #3eb6c4!important;
   }
   .table thead th {
       border-bottom: none !important;
       border-top: 1px solid #f2f4f9!important;
   }
   thead.tablebghead th
   {
       border-top: 1px solid #f2f4f9!important;
   }
   thead.tablebghead td {
       padding: .3em!important;
   }
   .tbbodystyle td p {
       font-size: 12px;
       letter-spacing: 0px;
       word-spacing: 0px;
       font-weight: normal;
       line-height: 14px;
       /* word-break: break-word; */
   }
   .tbbodystyle td {
    padding: 4px 5px!important;
}
   .innertbl {
    border: 1px solid #dcdcdc;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 5px;
    box-shadow: 0 1px 4px 0 rgb(223 212 212);

   }
   
   
   .reportsubicn {
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .reportactivityflex.ng-star-inserted {
       padding: 0 0.5em 0.5em 0.5em !important;
       margin: 5px 0px!important;
       box-shadow: 7px 0px 8px 4px #dee2e6;
       padding: 0 0.5em 0.5em 0.5em !important;
       box-shadow: 7px 0px 8px 4px #dee2e6;
       border-bottom: 2px solid #0997a9;
       border-left: 2px solid #0997a9;
       border-top: 2px solid #d4d4d4;
       border-right: 2px solid #d4d4d4;
       transition: all 0.6s ease;
           min-height: 140px;
       height: auto;
   }
   .reportsubicn mat-icon.mat-icon {
       font-size: 30px!important;
       margin: 10px 5px;
       width: 50px;
       height: 50px;
       background: #13b9cd;
       line-height: 50px;
       text-align: center;
       color: #fff!important;
       border-radius: 50px;
        transition: all 0.4s ease-in-out;
   }
   .reportactivityflex:hover{
       transform: translateY(-8px) !important;
       transition: all 0.4s ease-in-out;
       background: #13b9cd6e;
       color:#333;
   }
   .reportsubicn mat-icon.mat-icon:hover {
       background: #fff;
       color: #13b9cd!important;
       box-shadow: none;
       transition: all 0.4s ease-in-out;
   }

   .d-c16 {
    color: #3eb6c4;
    }

    .rowcoldelete
    {
    display: flex;
    }

    p.btn-pay .btn {
        background: #13b9cd!important;
    }
    button#register {
    background: #13b9cd!important;
    }
.navigation-wrapper nav.navbar.navbar-default.navbar-fixed-top.header {
    background: #13b9cd!important;
}
.mat-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.ng-image-fullscreen-view {
    position: fixed;
    z-index: 999999!important;
}
.ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .next, .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .prev
{
    line-height: 23px; 
}
.text{
    font-size: 14px;
    margin-top: 10px;
}
.text a span {
    margin: 5px;
}
.circular img {
    width: 100%;
    height: 85px;
}
.border_cstm{
    border: 1px solid #71777745;
    border-radius: 6px;
    margin: 10px 0px;
}
.fullbutton {
    border-bottom: 1px solid #3eb6c4;
    border-radius: 30px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 14px;
    margin-top: 8px;
    width: 90%;
    margin-left: 4px !important;
    cursor: pointer;
}
.text a {
    color: #333;
}
.text a:hover{
    color: #3eb6c4;
}

.rightcpbg
{
    float: right;
    margin-right: 10px!important;
}

.tophover:hover
{
    transform: translateY(-8px)!important;
    transition: all 0.3s;
    cursor: pointer;
}
/* .mat-select-trigger {
    border-radius: 40px;
    padding: 8px;
    border: 1px solid #dbd9d9;
} */

.flexvaluemin {
    display: flex;
    margin-top: -10px;
}
.flexvaluemin p {
    font-size: 12px;
    font-weight: 600; 
    margin-right: 15px;   
}
.flexvaluemin span {
font-size: 10px;
    font-weight: 100;
    color: #fff;
    background: rgb(107, 107, 2);
    margin: 1px;
    padding: 2px 3px;
    border-radius: 8px;
}
.ebmin {
    display: flex;
}
.mat-form-field-underline {
    background-color: none!important;
}
.mat-form-field-underline {
    height: 0px!important;
}
  .placedata {
    margin: 0px 5px;
}
.placedata ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    display:none;
}
.placedata ul li {
    font-size: 11px;
    text-transform: capitalize;
    padding: 0px 4px;
    cursor: pointer;
    
}
.placedata ul li:hover {
   cursor: pointer;
    background: #3eb6c4;
    border-radius: 20px;
    color: #fff;
}
.dataallflex {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    margin-bottom: 5px;
}

.avatar-circle {
    padding: 5px 10px;
    background: #3eb6c4;
    border-radius: 0 30px 0 0;
}

.definedContact {
    border-radius: 0 30px 0px 0;
}

.tooltip {
    display:inline-block;
    position:relative;
    border-bottom:1px dotted #666;
    text-align:left;
}

.tooltip .top {
    min-width:200px; 
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:10px 20px;
    color:#444444;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    display:none;
}

.tooltip:hover .top {
    display:block;
}

.tooltip .top i {
    position:absolute;
    top:100%;
    left:50%;
    margin-left:-12px;
    width:24px;
    height:12px;
    overflow:hidden;
}

.tooltip .top i::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#EEEEEE;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
}
.pldashboard {
    padding-left: 0px!important;
}
span.headngins.pldashboardmap {
    padding-left: 0px;
    margin-left: -15px;
}


.modal-body.nopaddingstep {
   padding: 0px!important;
}
   
.tophover {
    cursor: default!important;
}
   
.marker-cluster div {
	width: 55px!important;
    height: 55px!important;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center!important;
    border-radius: 15px;
    font: 11px Helvetica Neue,Arial,Helvetica,sans-serif!important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
	}

	.marker-cluster span {
		line-height: 14px!important;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.marker-cluster {
		background-clip: padding-box;
		/* border-radius: 20px; */
		width: 55px!important;
		height: 55px!important;
		border-radius: 50%;
	}

	.marker-cluster div {
		width: 45px!important;
		height: 45px!important;
		margin-left: 5px;
		margin-top: 5px;
		text-align: center;
		border-radius: 15px;
		font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}
    .meterS.bginactive {
        /* font-size: 14px; */
        color: #fff;
        background: #5d5d5f;
        height: 30px;
        width: 100%;
        /* padding-top: 5px; */
      }

      .griddashboadHcut.overflowy  {
        overflow-y: scroll;
    }
    
    .meterS.bginactive.redbgneg {
        background: #ff0000!important;
    }
    .meterS.bginactive.bggreenTotal {
        background: #9ebb3f!important;
    }
    .meterS.bginactive.redbgnegTotal {
        background: #ffa200!important;
    } 
   /* ***************************************All media qurey ********************/
   
   @media only screen  and (min-width : 1360px)
   {
   
       .imgcard .brand-logo, .imgcard img.img-fluid {
           width: 80%;
       }
   
      
    }

    @media only screen  and (max-width : 1260px)
   {
         .nav div.main_list ul li a {
        font-size: 14px;
        }
        .banner-content p {
            color: #ffdc2a;
            font-size: 17px;
            /* margin-bottom: 30px; */
            font-style: italic;
        }
        .innerbox.meterbalancmargnbtm {
            margin-bottom: -2px!important;
        }
    }

@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px) 
{
    .nav div.main_list ul li a {
    font-size: 12px!important;

}
   .nav div.main_list ul li {
    padding-right: 2rem!important;
}


}
   
   @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) 
   
   {
   .bgcurve {
    
       height: auto;
   }
   .ourbranchAll h3 {
       font-size: 14px!important;
   }
   .nav div.main_list ul li {
    width: auto;
    height: 65px;
    padding: 0;
    padding-right: 2rem;
}

   }



   
   @media screen and (max-width: 991px) {
   .threepoint {
       width: 90%;
       margin:5px auto;
   }
   .banner-graphicphone {
       display: none;
       }
       .smrightimgslider {
       display: none;
   }
   .bgcurve:after {
       display:none;
       }
   .bgcurve {
       height: auto;
       }
   .banner-content h2 {
       font-size: 25px;
       text-align: center;
   }
   .slidetoptxt {
       -ms-flex-pack: center;
       justify-content: center;
       align-items: center;
       display: flex;
       flex-direction: row;
       height: 100%;
   }
    .carousel-caption {
       top: 10%;
   }
   .banner-content p {
       text-align: center;
       color: #ffdc2a;
       font-size: 15px;
       margin-bottom: 20px;
   }
   .lognbtn {
       font-size: 14px;
       min-width: auto;
       border: none;
       margin: 6px auto; 
       display: block;
       border-radius: 25px;
       padding: 10px 25px;
   }
   .carousel-indicators {
       justify-content:center!important;
       bottom:0px;
   }
   .main-panel .navbar-fixed {
       width: 100%!important;
   
   }
   .footer-close footer {
       width: 100%!important;
   }

   img.cstm_logo
   {
    padding-top: 2px!important;
    padding-bottom: 2px;
    width: 120px;
    margin-top: 10px

   }
   .solutionflexsub i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    margin-top: -14px;
}

.modal-header.subpage {
    margin-bottom: 2px;
   }
   .modal-body {
    margin-bottom: 10px;
}
.modal-header.subpage h4 {
    margin-bottom: -5px;
   }
   i.fa.fa-file-excel-o {
    margin-left: -15px!important;
    margin-top: -4px;
}
.pldashboard {
    padding-left: 10px!important;
}
span.headngins.pldashboardmap {
    padding-left: 12px;
    margin-left: -15px;
}
.flexvaluemin {
    margin-top: 0px;
}

.meterbalancmargnbtm{
    margin-bottom: -50px!important;
}
.implink h5 {
    font-size: 11px!important;
}
   }

    
   @media screen and (max-width:991px) {
    .navTrigger {
         display: block;
         width: 16px;
         height: 15px;
         right: 11px;
         top: 6px;
         display: none;
     }
     .navTrigger i {
         background-color: #fff;
         border-radius: 2px;
         content: '';
         display: block;
         width: 100%;
         height: 1px;
     }
    .nav div.logo {
        padding-left: 5px;
    }
    .allsubtxtsmart i {
         width: 30px;
         height: 30px;
         line-height: 30px;
         margin-right: 10px;
     }
    .nav div.main_list {
        width: 100%;
        height: 0;
        overflow: hidden;
    }
    .nav div.show_list {
        height: auto;
        display: none;
    }
    .nav div.main_list ul {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #111;
        /*same background color of navbar*/
        background-position: center top;
    }
    .nav div.main_list ul li {
        width: 100%;
        text-align: center;
    }
    .nav div.main_list ul li a {
        text-align: center;
        width: 100%;
        font-size: 16px;
        padding: 20px;
    }
    .nav div.media_button {
        display: block;
    }
    .nav div.main_list ul li a:before {
    top: 44px;
    }

}
   @media screen and (max-width: 768px) {
   .boxcm {
       width: 100%;
   }
   .flexouter_award3 {
       display: flex;
       flex-direction: column;
       }
   
       .boxcm i {
       display: block;
   }
   .all_main_heading h2 {
       font-size: 1.4rem;
        margin-top: 0px;
   }
   .all_main_heading h3 {
       font-size: 14px;
       margin-bottom: 6px;
       margin-top: 8px;
   }
   .form-group {
    margin-bottom: 20px;
}
   .ourclientouter .client-list .item {
       width: 100%;
   }
   .imgcard {
       margin: 0px 0px 15px 0px;
   }
   .bgfootercurve {
      background: #2e83c0;
   }
   
   .imgcard img.img-fluid {
    top: 50%;
    width: 55%;
    margin: 0px auto;
   }
   .ourbranchAll h3 {
       margin-top: 30px;
       font-size: 12px!important;
   }
   .carousel {
       background: #fff;
   }
   .outermetersold {
       display: -ms-flexbox;
       display: flex;
       flex-direction: column;
   }
   .submetersold {
       border-right: none;
   }
   .bgsoldimage {
       background-repeat: no-repeat;
       height: auto;
       background-color: #2e83c0;
   }
   .submetersold {
       margin: 10px 5px;
   }
   .threepointouter {
       display: -ms-flexbox;
       display: flex;
       -ms-flex-direction: revert;
       flex-direction: column;
       -ms-flex-pack: center;
       justify-content: center;
       -ms-flex-align: center;
       align-items: center;
   }
   .circleouterIRDA {
       margin: 40px auto -45px;
       position: static;
   }
   .countermap {
       bottom: 0px;
       -ms-transform: translate(-50%, -50%);
       transform: translate(-50%, -50%);
       position: static;
       }
       .cstmborder::before {
       content: "";
       width: 20px;
       }
       .cstmborder::after {
       content: "";
       width: 20px;
       }
   
       .hqname {
       margin-top: 15px;
       }
       .socialiconfooter {
       margin-top: 25px;
   }
   
   .dilog {
    /* padding: 4px 10px; */
    font-size: 16px;
    height: 36px;
    margin-bottom: 10px;
   }
   .our_productU {
       flex-direction: column;
     
   }
   .login-form-1 {
       border-radius: 15px;
       margin-bottom: 15px;
   }
   .login-form-2 {
       border-radius: 15px;
   }
   
   .imgcard {
    width: 90%;
    height: 259px;
    margin: 10px auto;
   }
   .ourclientouter .client-list, .ourclientouter .client-list .item {
       flex-direction: column;
   }
   .allsubtxtsmart li {
    font-size: 13px;
    /* display: flex;
    align-items: center; */
    
    }
    .all_main_heading p {
    font-size: 14px;
    line-height: 18px;
}
.all_main_heading p {
    color: #333;
    font-size: 13px;
    text-align: justify;
    line-height: 18px;
}
p {
    font-size: 13px;
    line-height: 18px;
}
.producttxtthree p {
    text-align: center;
    color: #333;
    font-size: 13px;
}
p.awardstartxt {
    margin-top: 5px;
    margin-bottom: 10px;
}
.boxcm p {
    font-size: 13px;
        line-height: 19px;
}
h3.lblpr {
    color: #333;
    font-size: 14px!important;
   }
   .intrvwLI li {
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
   }
   .intrvwLI li i {
    font-weight: 400;
    font-size: 17px!important;
    line-height: 32px !important;
    color: #2e83c0!important;
    margin-left: 0em;
    letter-spacing: 1px;
    color: #fff;
    font-size: 25px;
    padding-right: 15px;
    padding-right: 25px;
    }
    .othersm {
        display: none;
    }
    .bgcontactus {
    margin: 0px 0px;
   }
   .box_pr p {
    text-align: center;
    }
    .slidetoptxt h3 {
    text-align: center;
    margin-top:15px;
    font-size: 14px;

}
.mrtop35 {
    margin-top: 12px;
}
img.cstm_logo {
   padding-top: 2px!important;
    padding-bottom: 2px;
    width: 120px;
    margin-top: 10px
}
.aboutflexcentr p {
    line-height: 18px;
}
p.mrtop35.subblue.aos-init.aos-animate {
    line-height: 18px;
}
.align_center_txt {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
}
.box_pr h2 {
    font-size: 14px;
}

section#branches {
    display: none;
}
.sidebar .user {
    padding-bottom: 6px;
}
i.nc-icon.nc-bullet-list-67 {
    margin-top: -8px;
}

.listaddinfogrid_bg {
    margin-top: 74px;
    margin-right: 30px;
    margin-left: 30px;
}

/* .mat-paginator-page-size {
    margin-top: -26px!important;
} */

.mat-paginator-container {
    justify-content: space-around!important;

}

.mat-paginator-page-size {
    display: block;
}


button.mat-focus-indicator.mat-tooltip-trigger {
    width: auto!important;
}

.mat-paginator-range-label {
    margin: 0 12px 0 12px!important;
}

.mat-paginator-icon {
    width: 20px;
}
.mat-paginator-outer-container {
    margin-top: -10px!important;
}
.mat-paginator-page-size-label {
    margin: 0 0px!important;
}
.mat-paginator-page-size {
     display: block!important;
    align-items: self-start;
    margin-right: initial;
}

.mat-paginator-page-size-select {
    margin: 0px 0px 0 0px;
    /* width: 30px; */
}

.mat-paginator-container {
    margin-bottom: 30px!important;
}
.dilog3 {
   width: 100px;
    padding-left: 5px;
    margin: 0px;
   }

.dilog1 {
    width: 100px;
    padding-left: 5px;
    margin: 0px;
   }

   .frowbtn button.ml-2.dilog.dilog1
    {
        margin-top: 5px!important; 
        padding-left: 16px; 
    }

    .meterbalancmargnbtm{
        margin-bottom: auto!important;
    }
    .implink h5 {
        font-size: 11px!important;
   
}
   }
@media (max-width: 520px) {

.meterbalancmargnbtm{
    margin-bottom: -40px!important;
}
.innerbox.meterbalancmargnbtm {
    margin-bottom: -2px!important;
}
}

@media (max-width: 440px) {
    .banner-content h2 {
    font-size: 16px;
    text-align: center;
}
.banner-content p {
font-size: 14px;
text-align: center;
margin-bottom: 8px;
text-align: center;
}
.lognbtn {
    font-size: 16px;
    width: auto;
    border: none;
    margin: 8px auto;
    display: block;
    border-radius: 25px;
    padding: 11px 25px;
    }

    .dilog3 {
   width: 100px;
    padding-left: 5px;
    margin: 0px;
   }

.dilog1 {
    width: 100px;
    padding-left: 5px;
    margin: 0px;
   }
   .mat-paginator-outer-container {
    margin-top: -5x!important;
}
.mat-paginator-page-size-label {
    margin: 0 0px!important;
}
.mat-paginator-page-size {
    display: block!important;
    align-items: self-start;
    margin-right: initial;
}

.mat-paginator-page-size-select {
    margin: 0px 0px 0 0px;
  
}

.mat-paginator-container {
    min-height: 100px!important;
}
.mat-paginator-outer-container {
    margin-top: -10px!important;
}
.mat-paginator-page-size-label {
    margin: 0 0px!important;
   
}
.mat-paginator-page-size {
     display: block!important;
    margin-right: initial;
}

.mat-paginator-page-size-select {
    margin: 0px 0px 0 0px;
    /* width: 30px; */
}

.mat-paginator-container {
    min-height: 100px!important;
}
.mat-paginator-range-actions {
    display: block!important;
}
.frowbtn button.ml-2.dilog.dilog1
{
      margin-top: 5px!important;  
    padding-left: 16px;
}


}


fieldset.scheduler-border {
    border: 2px solid#3eb6c4;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 3px 6px 9px 3px #dadada;
    box-shadow:  3px 6px 9px 3px #dadada;
    border-radius: 15px;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
}


